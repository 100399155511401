<template>
  <div class="footer-container">
    <footer class="footer" role="contentinfo">
      <div class="col-lg-12 col-md-12 text-center">
        <p class="copyright">
          Shinier Soluções Empresariais Tecnológicas LTDA - CNPJ
          34.675.142/0001-70 © Copyright {{ new Date().getFullYear() }} Shinier.
          Todos os direitos reservados.
          <a
            style="text-decoration: underline"
            class="copyright politica-privacidade"
            @click="$router.push({ name: 'Politica' })"
            >Política de Privacidade</a
          >
        </p>
      </div>
    </footer>
    <div
      v-if="!accept.isAccept"
      id="cookie-law-info-bar"
      data-nosnippet="true"
      data-cli-style="cli-style-v2"
      style="
        color: rgb(51, 51, 51);
        font-family: inherit;
        bottom: 0px;
        position: fixed;
        display: block;
       
      "
    >
      <span
        ><div class="row">
          <div class="col-sm-12 col-md-9">
            Usamos cookies em nosso site para fornecer as informações mais
            relevantes a sua experiência.<br />
            Clicando em “Aceitar”, você concorda com o uso de TODOS os cookies.
          </div>
          <div class="col-sm-12 col-md-3">
            <a
              role="button"
              tabindex="0"
              class="cli_settings_button"
              style="margin: 0px 10px 0px 5px; color: rgb(51, 51, 51)"
              @click="$router.open({ name: 'Politica' })"
            >
              Politica de dados
            </a>
            <a
              role="button"
              tabindex="0"
              data-cli_action="accept"
              id="cookie_action_close_header"
              class="
                medium
                cli-plugin-button cli-plugin-main-button
                cookie_action_close_header
                cli_action_button
              "
              style="
                display: inline-block;
                color: rgb(255, 255, 255);
                background-color: rgb(173 135 69);
              "
              @click="accept_cookie()"
            >
              ACEITAR
            </a>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
/* import $ from "jquery"; */
import store from "../../store";

export default {
  props: ["login"],
  data() {
    return {
      accept: {
        isAccept: false,
        tracking: ""
      },
      hoverLi: false
    };
  },

  methods: {
    get_cookie() {
      if (store.getters.isAccept) {
        this.accept.isAccept = true;
        this.accept.tracking +=
          store.getters.tracking + ", " + this.$route.name;
        this.$store.dispatch("accept", this.accept);
      }
    },
    accept_cookie() {
      this.accept.isAccept = true;
      this.accept.tracking = this.$route.name;
      this.$store.dispatch("accept", this.accept);
    }
  },
  created() {
    this.get_cookie();
  },
  beforeCreate() {
    if (document.addEventListener) {
      document.addEventListener("contextmenu", function(e) {
        e.preventDefault();
        return false;
      });
    } else {
      //Versões antigas do IE
      document.attachEvent("oncontextmenu", function(e) {
        e = e || window.event;
        e.returnValue = false;
        return false;
      });
    }

    if (document.addEventListener) {
      document.addEventListener("keydown", bloquearSource);
    } else {
      //Versões antigas do IE
      document.attachEvent("onkeydown", bloquearSource);
    }

    function bloquearSource(e) {
      e = e || window.event;

      var code = e.which || e.keyCode;

      if (
        e.ctrlKey &&
        (code == 83 || code == 85) //83 = S, 85 = U
      ) {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }

        return false;
      }
    }
  }
};
</script>
